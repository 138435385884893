import React from 'react';
import { Link } from 'gatsby';

const Quality = () => (
  <section id="char" className="min-h-screen flex flex-col content-center relative py-16 md:py-24 lg:py-36 px-4 md:px-0">
    <div className="absolute top-0 right-0 p-24 translate-r-full ">
      <svg
        className="hidden md:block h-48 w-48 transform rotate-180 text-green-300"
        viewBox="0 0 184 184"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M182 184a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm-20-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm-20 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm-20 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-60a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm-20 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-60a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm-20 40a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-60a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm-20 60a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-60a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm-20 80a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-60a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM22 144a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-60a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM2 144a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-60a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM2 4a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
          fill="currentColor"
          fillRule="evenodd"
        ></path>
      </svg>
    </div>
    <div className="absolute top-1/4 left-0 translate-l-full opacity-25 xl:opacity-100" style={{zIndex:-1}}>
      <svg
        className="hidden md:block text-green-200"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
        style={{zIndex: -1}}
      >
        <defs>
          <pattern id="pattern" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <circle cx="8" cy="8" r="2.5" fill="currentColor"></circle>
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#pattern)"></rect>
      </svg>
    </div>
    <div className="text-center container mx-auto">
      <h2 className="mx-auto max-w-2xl mt-8 lg:mt-0 text-4xl md:text-5xl leading-tight font-bold text-gray-900"
      data-sal="slide-up" data-sal-easing="ease" data-sal-duration="600"
      >
        Desarrollamos software enfocandonos en la calidad
      </h2>
      <p
        className="mx-auto max-w-4xl text-xl md:text-2xl mt-8 text-gray-700" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="750"
      >
        Aqui en 
        <span className="pl-2">Cantabria Digital</span>
        {' '}
        creemos que tu deberias centrarte en tu negocio mientras nosotros nos hacemos cargo de todo lo demás.
        Nosotros creemos en un <Link className="link-touch">diseño web de calidad</Link>.
        Entregando el maximo valor tanto a ti como a tus clientes es lo que nos distingue de
        competencia, y es exactamente lo mismo que {' '}
        <span className="font-bold">te distinguirá de la competencia </span>
        si trabajamos juntos.
      </p>
    </div>
    <div className="container mx-auto mt-16 grid grid-cols-1 md:grid-cols-3 gap-6">
      {/* Carta */}
      <div className="rounded bg-white shadow-lg p-5" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="250" data-sal-delay="200">
        <span className="text-green-600 font-semibold text-xl">01 -</span>
        <h4 className="font-bold text-2xl mt-3">Valoramos tu tiempo</h4>
        <p className="text-gray-800 mt-3">
          Sabemos como de preciado es el tiempo. Tratamos de quitarte tantas cosas de la cabeza como sea posible.
        </p>
      </div>

      {/* Carta */}
      <div className="rounded bg-white shadow-lg p-5" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="350" data-sal-delay="300">
        <span className="text-green-600 font-semibold text-xl">02 -</span>
        <h4 className="font-bold text-2xl mt-3">Buscamos la perfección</h4>
        <p className="text-gray-800 mt-3">
          No creemos en tomar atajos. Siempre tratamos de pulir todas las caracteristicas de tu aplicación.
        </p>
      </div>

      {/* Carta */}
      <div className="rounded bg-white shadow-lg p-5" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="350" data-sal-delay="400">
        <span className="text-green-600 font-semibold text-xl">03 -</span>
        <h4 className="font-bold text-2xl mt-3">Comunicación eficiente</h4>
        <p className="text-gray-800 mt-3">
          Te mantendremos informado sobre cómo están progresando las cosas y para discutir ideas.
        </p>
      </div>

      {/* Carta */}
      <div className="rounded bg-white shadow-lg p-5" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="250" data-sal-delay="200">
        <span className="text-green-600 font-semibold text-xl">04 -</span>
        <h4 className="font-bold text-2xl mt-3">Todo incluido</h4>
        <p className="text-gray-800 mt-3">
          Un precio único en el que se incluyen todo los gastos desde mantenimiento, hosting, modificaciones, etc.
        </p>
      </div>

      {/* Carta */}
      <div className="rounded bg-white shadow-lg p-5" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="350" data-sal-delay="300">
        <span className="text-green-600 font-semibold text-xl">05 -</span>
        <h4 className="font-bold text-2xl mt-3">Premiamos tu fidelidad</h4>
        <p className="text-gray-800 mt-3">
          No creemos en tomar atajos. Siempre tratamos de pulir todas las caracteristicas de tu aplicación.
        </p>
      </div>

      {/* Carta */}
      <div className="rounded bg-white shadow-lg p-5" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="350" data-sal-delay="400">
        <span className="text-green-600 font-semibold text-xl">06 -</span>
        <h4 className="font-bold text-2xl mt-3">Sin compromiso de permanencia</h4>
        <p className="text-gray-800 mt-3">
          Puedes cancelar tu subscripcion web en cualquier momento, solo queremos que te quedes si estás contento con nuestro servicio.
        </p>
      </div>
    </div>
  </section>
);

export default Quality;

import React from 'react'
import { StaticQuery, graphql} from 'gatsby';
import ArticleCard from "../article/card"

const query = graphql`{
  strapiHomepage {
    title
    id
    articles {
      title
      content
      description
      image {
        localFile{
          childImageSharp {
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
        }
      }
      author
    }
  }
  allStrapiArticles(limit: 3, sort: {fields: published_at, order: DESC}) {
    edges {
      article: node {
        id
        title
        description
        content
        image {
          localFile{
            childImageSharp {
              gatsbyImageData(width: 960, layout: CONSTRAINED)
            }
          }
        }
        author {
          id
          username
        }
        categories {
          name
        }
      }
    }
  }
}
`;

const IndexPage = () => (
  <StaticQuery
    query={query}
    render={data => (
      <section id="blog" className="bg-green-50 py-10 md:py-24 px-4 md:px-0">
        <div className="container mx-auto w-full lg:w-5/6">
          <div className="text-center mb-6">
            <h2 className="text-4xl md:text-5xl text-gray-900 font-bold m-0 ">{data.strapiHomepage.title}</h2>
          </div>
            
          <div className="grid grid-cols-1 lg:grid-cols-3">
            {data.allStrapiArticles.edges.map(function(article,index){
              return(
                <div key={index} className="mr-0 md:mr-8" >
                  <ArticleCard data={article} picture={true}/>
                </div>
              )
            })}               
          </div>
          
        </div>
      </section>
    )}
  />
);

export default IndexPage;
import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import {useStaticQuery, graphql } from "gatsby"
import BigDots from "../background/bigDots"

const Speed = (id) => {
  const data = useStaticQuery(graphql`query MyQuery {
  file(relativePath: {eq: "speed_screenshot_black.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
}
`)
  return (
    <section className="relative py-16 sm:py-24 px-4 md:px-0" id="serv">
      <div className="absolute right-0 bottom-0 hidden md:block gatsby-image-wrapper text-green-200" style={{position:'absolute' ,overflow:'hidden',width:'300px',height:'544px',zIndex:'-1'}}>
         <BigDots/>
      </div>
      <div className="container mx-auto">
        <div className="text-center">
          <h2
            className="text-4xl md:text-5xl text-gray-900 font-bold mt-2 max-w-4xl mx-auto"
            data-sal="slide-up" data-sal-easing="ease" data-sal-duration="300"
          >
            Creamos webs con pasión.
          </h2>
          <p
            className="text-xl text-gray-700 max-w-3xl mx-auto"
            data-sal="slide-up" data-sal-easing="ease" data-sal-duration="900"
          >
              Estamos un poco locos por los pequeños detalles en nuestro proceso de desarrollo.
              Creamos soluciones a medida para satisfacer tus necesidades en cada proyecto que empezamos.
          </p>
        </div>
        <div className="lg:flex items-center md:justify-between">
          <div className="lg:w-2/5 pl-0 md:pl-3">
            <div className="mt-8">
              {/* column */}
              <div className="mt-8" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="400">
                <div className="flex w-10 h-10 items-center justify-around bg-green-600 rounded-lg">
                  <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6 text-white">
                    <path
                      fillRule="evenodd"
                      d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <h4 className="mt-3 font-semibold text-gray-900 text-2xl">
                  Creadas con el rendimiento en mente.
                </h4>
                <p className="text-gray-700 text-lg mt-1">
                  La mayoría de los usuarios web abandonarán su página si no es lo suficientemente rápida. Ponemos mucho
                  Esfuerzo en hacer que su aplicación sea lo más rápida posible, optimizándola a la perfección.
                </p>
              </div>

              {/* column */}
              <div className="mt-8" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="800">
                <div className="flex w-10 h-10 items-center justify-around bg-green-600 rounded-lg">
                <svg className="text-white w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </div>
                <h4 className="mt-3 font-semibold text-gray-900 text-2xl">
                  Optimizadas para el SEO.
                </h4>
                <p className="text-gray-700 text-lg mt-1">
                  Creemos que el trafico organico es el mejor. Por ello optimizamos todas nuestras páginas para ofrecer los mejores resultados en SEO.
                </p>
              </div>

              {/* column */}
              <div className="mt-8" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1200">
                <div className="flex w-10 h-10 items-center justify-around bg-green-600 rounded-lg">
                  <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6 text-white">
                    <path
                      fillRule="evenodd"
                      d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <h4 className="mt-3 font-semibold text-gray-900 text-2xl">
                  Intuitivas y accesibles.
                </h4>
                <p className="text-gray-700 text-lg mt-1">
                  Siempre seguimos las mejores practicas para conseguir que nuestras web no sean solo impresionantes visualmente sino que ademas se acomode a las necesidades especificas de cada usuario.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4 md:mt-0 lg:w-3/5 xl:w-2/5">
            <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} />
          </div>
        </div>
        {/** Button */}
        <div className="mx-auto">
          <div className="mt-4 md:mt-8 flex flex-row justify-center">
              <Link
              className="group w-max py-3 px-8 bg-green-500 hover:bg-black rounded-lg text-white font-black transition duration-500 flex items-center"
              type="button"
              to="/contact/"
              >
                  Contacta con nosotros
                  <svg
                  className="h-6 ml-3 ease-in-out transition duration-500 transform group-hover:translate-x-2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  >
                  <path d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                  </svg>
              </Link>       
          </div>
        </div>
      </div>
    </section>
  );
};
export default Speed;

import React from 'react';
import HeroUndraw from "../../static/svg/images/undraw_web_build"
import { Link } from 'gatsby';
import TypedLetters from '../typedLetters';
import TriangleDots from '../background/triangleDots';
import ServicesGrid from '../servicesGrid'
const Hero = () => (
  <div>
    <TriangleDots></TriangleDots>
 
    <div className="container mx-auto md:py-10 px-8 md:flex items-center max-h-1/2 max-h-full">
      <div className="md:w-1/2 md:text-left flex flex-col items-center md:items-left text-center">
        <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
          Nosotros creamos <span className="block md:inline">páginas{' '}</span>
          <TypedLetters strings={['rápidas', 'seguras', 'modernas', 'bonitas', 'a medida']} />
        </h1>
        <p className="text-xl lg:text-2xl mt-6 font-light text-left">
          Especialistas en la creación de páginas web para pymes y autónomos
        </p>
        <p className="mt-8 md:mt-12 self-center md:self-start">
          <Link
            className="group py-3 px-8 bg-green-500 hover:bg-black rounded-lg text-white font-black transition duration-500 flex items-center"
            type="button"
            to="/contact/"
          >
            Contacta con nosotros
            <svg
              className="h-6 ml-3 ease-in-out transition duration-500 transform group-hover:translate-x-2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
            </svg>
          </Link>
        </p>
      </div>
      <div className="w-full py-4 md:py-0 md:w-1/2 flex items-center text-center">
        <HeroUndraw className="w-3/4 md:w-full self-center" />
      </div>
      
    </div>
    <ServicesGrid/>
  </div>
);

export default Hero;

import React from 'react';
import '../static/scss/serviceGrid.scss';

function servicesGrid() {
  return (
    <div className="container mx-auto px-8 py-4">
        <div>
            <p className="label">Esto es lo que ofrecemos:</p>
        </div>
        <div className="grid-cols-1 md:grid-cols-3 grid mt-6 mb-10">
            <div className="md:border-r border-gray-300 p-3 fadein-load">
                <a className="-m-3 p-3 flex items-start space-x-4 rounded-lg md:rounded-l-lg hover:bg-gray-50 transition ease-in-out duration-150">
                <svg className="flex-shrink-0 h-6 w-6 text-green-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                </svg>
                <div className="space-y-1">
                    <p className="text-xl leading-6 font-medium text-gray-900">
                    Páginas de calidad
                    </p>
                    <p className="text-base leading-5 text-gray-500">
                    Nuestras webs son rápidas, seguras y creadas desde cero para garantizar los mejores resultados.
                    </p>
                </div>
                </a>
            </div>
            <div className="md:border-r border-gray-300 p-3 fadein-load">
                <a className="-m-3 p-3 flex items-start rounded-lg md:rounded-none space-x-4 hover:bg-gray-50 transition ease-in-out duration-150" >
                    <svg className="flex-shrink-0 h-6 w-6 text-green-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"></path></svg>
                    <div className="space-y-1">
                        <p className="text-xl leading-6 font-medium text-gray-900">Diseño web</p>
                        <p className="text-base leading-5 text-gray-500">Gracias al diseño conseguiras tener una imagen de marca única y diferenciarte de tus competidores.</p>
                    </div>
                </a>
            </div>

            <div className="p-3 fadein-load">
                <a className="-m-3 p-3 flex items-start space-x-4 rounded-lg md:rounded-r-lg hover:bg-gray-50 transition ease-in-out duration-150" >
                    <svg className="flex-shrink-0 h-6 w-6 text-green-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                        <path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path>
                    </svg>
                    <div className="space-y-1">
                        <p className="text-xl leading-6 font-medium text-gray-900">Trato personalizado</p>
                        <p className="text-base leading-5 text-gray-500">Entendemos que no todos nuestros clientes tienen las mismas necesidades.</p>
                    </div>
                </a>
            </div>
      </div>
    </div>
  );
}

export default servicesGrid;

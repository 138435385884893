import React from 'react'
import { Link } from 'gatsby'
import slugify from "slugify"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Card = ({ data , picture=false }) => {
    var image_var = '';
    if(picture){
        image_var = getImage(data.article.image.localFile.childImageSharp.gatsbyImageData)
    } 
    return (
        <div className={picture ? " grid grid-rows-2 grid-cols-1 h-full" : "flex flex-col mt-2"}> 
            { picture ? <GatsbyImage alt="Blog image" className="rounded-t-lg mt-2 h-48 object-cover self-end" image={image_var} /> : <></>}
            <div className="p-6 bg-white h-full rounded-b-lg shadow-xl" key={data.article.id}>
                
                <div className="text-green-500 font-bold text-sm flex flex-row">
                    {data.article.categories.map(
                        function(cat, i){
                            return(
                                <Link key={i} to={"/categories/"+slugify(cat.name)+"/"} className="mr-4 bg-green-100 hover:bg-green-200 hover:text-green-900 hover:font-bold font-medium text-green-800 px-2 py-1 rounded-lg">{cat.name}</Link>
                            )
                        }
                    )}
                </div>

                <Link to={"/"+slugify(data.article.title)+"/"}>
                <h3 className="text-lg my-2 font-bold text-gray-600 hover:text-green-700">{data.article.title}</h3>
                </Link> 
                <p className="truncate-text text-gray-600">{data.article.description}</p>
            </div> 
        </div> 
    )   
  }

export default Card

import React from 'react';
import '../static/scss/divider.scss'
import Layout from '../components/layout/Layout';
import {Helmet} from "react-helmet";
import {Hero, Quality, Tech, Blog, Speed} from '../components/sections/'

const Index = () => (
  <Layout>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Cantabria digital: El mejor diseño web de cantabria</title>
        <meta name="description" content="Paginas web hechas a mano y personalizadas, un diseño único que hara que tu negocio se diferencie del resto" />
        <html lang="es"/>
        <link rel="canonical" href="https://cantabriadigital.es" />
    </Helmet>
    <section className="pt-15 hero">
      <Hero/>
    </section>
    <Quality/>
    <Speed/>
    <Tech/> 
    <Blog/>
  </Layout>
);
export default Index;
import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 876.272 661.473"
    >
      <path
        fill="#2f2e41"
        d="M685.834 138.655c7.516 15.995-8.41 20.525-26.655 29.099s-31.898 17.942-39.414 1.947-13.615-47.743 12.195-59.871c26.7-12.546 46.358 12.83 53.874 28.825z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <circle cx="467.746" cy="39.089" r="24.561" fill="#ffb8b8"></circle>
      <path
        fill="#2f2e41"
        d="M770.29 254.473c-.85-1.11-1.7-2.2-2.56-3.26q-1.664-2.07-3.35-4.01c-12.73-14.69-26.27-23.94-41.18-24.93h-.02l-.22-.02-29.71 28.96-10.1 9.84.22.54 5.45 13.57h95.49a234.834 234.834 0 00-14.02-20.69zM621.902 155.438l8.792-4.132-1.225-12.683 5.88 10.496 4.202-1.974-.715-7.399 3.43 6.123 31.224-14.673a26 26 0 00-34.589-12.474l-4.525 2.127a26 26 0 00-12.474 34.589z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#f2f2f2"
        d="M825.39 601.26H312.552a16.519 16.519 0 01-16.5-16.5V276.608a12.102 12.102 0 0112.088-12.089h521.453a12.311 12.311 0 0112.297 12.298V584.76a16.519 16.519 0 01-16.5 16.5z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#fff"
        d="M328.552 584.76H809.39a16 16 0 0016-16V307.214a16 16 0 00-16-16H328.552a16 16 0 00-16 16V568.76a16 16 0 0016 16z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#10b981"
        d="M841.66 275.663H295.82v-8.4a16.574 16.574 0 0116.56-16.55H825.1a16.574 16.574 0 0116.56 16.55z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <circle cx="145.051" cy="156.752" r="4.283" fill="#fff"></circle>
      <circle cx="161.31" cy="156.752" r="4.283" fill="#fff"></circle>
      <circle cx="177.569" cy="156.752" r="4.283" fill="#fff"></circle>
      <path
        fill="#ccc"
        d="M583.974 528.04h-43a9.51 9.51 0 01-9.5-9.5v-43a9.51 9.51 0 019.5-9.5h43a9.51 9.51 0 019.5 9.5v43a9.51 9.51 0 01-9.5 9.5zm-43-60a7.508 7.508 0 00-7.5 7.5v43a7.508 7.508 0 007.5 7.5h43a7.508 7.508 0 007.5-7.5v-43a7.508 7.508 0 00-7.5-7.5z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#ccc"
        d="M575.474 516.04h-26a3.003 3.003 0 01-3-3v-32a3.003 3.003 0 013-3h18.84a3.007 3.007 0 011.88.661l7.16 5.754a2.984 2.984 0 011.12 2.338v26.247a3.003 3.003 0 01-3 3zm-26-36a1.001 1.001 0 00-1 1v32a1.001 1.001 0 001 1h26a1.001 1.001 0 001-1v-26.247a.995.995 0 00-.373-.779l-7.161-5.754a1.002 1.002 0 00-.626-.22z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#ccc"
        d="M570.474 492.04h-16a1 1 0 010-2h16a1 1 0 010 2zM570.474 498.04h-16a1 1 0 010-2h16a1 1 0 010 2zM570.474 504.04h-16a1 1 0 010-2h16a1 1 0 010 2zM685.974 528.04h-43a9.51 9.51 0 01-9.5-9.5v-43a9.51 9.51 0 019.5-9.5h43a9.51 9.51 0 019.5 9.5v43a9.51 9.51 0 01-9.5 9.5zm-43-60a7.508 7.508 0 00-7.5 7.5v43a7.508 7.508 0 007.5 7.5h43a7.508 7.508 0 007.5-7.5v-43a7.508 7.508 0 00-7.5-7.5z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#ccc"
        d="M678.474 508.04h-28a3.003 3.003 0 01-3-3v-16a3.003 3.003 0 013-3h28a3.003 3.003 0 013 3v16a3.003 3.003 0 01-3 3zm-28-20a1.001 1.001 0 00-1 1v16a1.001 1.001 0 001 1h28a1.001 1.001 0 001-1v-16a1.001 1.001 0 00-1-1z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#ccc"
        d="M664.474 499.678a3.003 3.003 0 01-1.59-.453l-14.94-9.337a1 1 0 011.06-1.697l14.941 9.338a1.006 1.006 0 001.06 0l14.94-9.338a1 1 0 011.06 1.697l-14.94 9.337a3.004 3.004 0 01-1.59.453zM481.974 528.04h-43a9.51 9.51 0 01-9.5-9.5v-43a9.51 9.51 0 019.5-9.5h43a9.51 9.51 0 019.5 9.5v43a9.51 9.51 0 01-9.5 9.5zm-43-60a7.508 7.508 0 00-7.5 7.5v43a7.508 7.508 0 007.5 7.5h43a7.508 7.508 0 007.5-7.5v-43a7.508 7.508 0 00-7.5-7.5z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#ccc"
        d="M474.474 508.04h-28a3.003 3.003 0 01-3-3v-16a3.003 3.003 0 013-3h28a3.003 3.003 0 013 3v16a3.003 3.003 0 01-3 3zm-28-20a1.001 1.001 0 00-1 1v16a1.001 1.001 0 001 1h28a1.001 1.001 0 001-1v-16a1.001 1.001 0 00-1-1z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#ccc"
        d="M470.036 508.04h-20.124a2 2 0 01-1.732-3l6.062-10.5a2 2 0 013.464 0l3.915 6.78 2.268-3.928a2 2 0 013.465 0l4.415 7.648a2 2 0 01-1.733 3zm0-2l-4.415-7.648-3.133 5.429a1 1 0 01-.867.5 1 1 0 01-.866-.5l-4.78-8.281-6.063 10.5z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <circle cx="290.61" cy="385.579" r="2" fill="#ccc"></circle>
      <path
        fill="#ffb8b8"
        d="M768.825 648.15L756.565 648.149 750.733 600.861 768.827 600.862 768.825 648.15z"
      ></path>
      <path
        fill="#2f2e41"
        d="M952.816 766.494l-39.53-.001v-.5a15.386 15.386 0 0115.386-15.386h24.144z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M818.825 648.15L806.565 648.149 800.733 600.861 818.827 600.862 818.825 648.15z"
      ></path>
      <path
        fill="#2f2e41"
        d="M1002.816 766.494l-39.53-.001v-.5a15.386 15.386 0 0115.386-15.386h24.144zM996.014 734.11l-11.355-3.453a4.493 4.493 0 01-3.165-3.833l-10.586-100.326a.48.48 0 00-.479-.448.47.47 0 00-.51.41l-17.731 97.199a4.5 4.5 0 01-5.335 3.6l-14.511-2.99a4.516 4.516 0 01-3.582-4.712l10.785-159.312 73.138-7.892-10.868 177.727a4.505 4.505 0 01-4.484 4.227 4.526 4.526 0 01-1.317-.196z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <circle cx="815.08" cy="290.487" r="24.561" fill="#ffb8b8"></circle>
      <path
        fill="#10b981"
        d="M939.433 561.18l15.858-99.85a37.857 37.857 0 0148.005-30.4 37.743 37.743 0 0126.601 43.262l-16.59 89.172z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M1002.725 579.046a10.056 10.056 0 001.747-15.32l16.396-31.751-18.465 1.97-12.739 29.85a10.11 10.11 0 0013.061 15.251z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#10b981"
        d="M1006.236 558.126a4.506 4.506 0 01-2.427-1.151l-6.273-5.797a4.515 4.515 0 01-1.092-5.054l12.538-29.718-1.779-58.72a14.497 14.497 0 1128.727 3.919l3.041 36.912a46.373 46.373 0 01-8.937 31.397l-19.552 26.432a4.505 4.505 0 01-3.196 1.804 4.455 4.455 0 01-1.05-.024z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M854.505 383.566a10.056 10.056 0 0013.427 7.582l22.845 27.478 5.385-17.772-22.52-23.37a10.11 10.11 0 00-19.137 6.082z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#10b981"
        d="M872.401 394.955a4.506 4.506 0 012.006-1.785l7.784-3.517a4.515 4.515 0 015.08.965l22.478 23.132 54.766 21.257a14.497 14.497 0 11-14.81 24.926L914.53 448.34a46.374 46.374 0 01-25.427-20.472l-16.717-28.31a4.505 4.505 0 01-.416-3.646 4.454 4.454 0 01.432-.958z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        d="M835.002 375.652L834.002 407.652 815.58 439.663 830.962 403.99 835.002 375.652z"
        opacity="0.1"
      ></path>
      <path
        fill="#2f2e41"
        d="M993.13 401.563a6.423 6.423 0 01-7.03-4.702 9.764 9.764 0 00-6.95-7.274c-4.506-1.03-10.013 1.092-13.314-2.322a6.637 6.637 0 01-1.576-5.842c.608-3.318 2.981-5.824 5.564-7.828a34.062 34.062 0 0118.866-7.05 67.677 67.677 0 0110.62.528 47.01 47.01 0 0110.864 1.985 25.576 25.576 0 0116.002 18.68 29.528 29.528 0 01-6.057 24.13 31.702 31.702 0 01-12.16 9.26 4.744 4.744 0 01-3.607.182c-2.957-1.175-2.474-4.839-1.126-7.282 1.443-2.615 3.664-5.147 2.503-8.329a6.302 6.302 0 00-3.127-3.366c-2.94-1.5-6.228-1.144-9.389-.78z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#fff"
        d="M846.297 415.433h-304a16.519 16.519 0 01-16.5-16.5v-65a16.519 16.519 0 0116.5-16.5h304a16.519 16.519 0 0116.5 16.5v65a16.519 16.519 0 01-16.5 16.5z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#3f3d56"
        d="M846.297 415.933h-304a17.02 17.02 0 01-17-17v-65a17.02 17.02 0 0117-17h304a17.02 17.02 0 0117 17v65a17.02 17.02 0 01-17 17zm-304-97a15.017 15.017 0 00-15 15v65a15.017 15.017 0 0015 15h304a15.017 15.017 0 0015-15v-65a15.017 15.017 0 00-15-15z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#f2f2f2"
        d="M809.297 399.433h-230a16.519 16.519 0 01-16.5-16.5v-34a16.519 16.519 0 0116.5-16.5h230a16.519 16.519 0 0116.5 16.5v34a16.519 16.519 0 01-16.5 16.5z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <circle cx="447.928" cy="254.915" r="19.732" fill="#ff6584"></circle>
      <path
        fill="#3f3d56"
        d="M795.004 397.442l-27.852-23.36-27.372-22.956a4 4 0 00-5.153.01l-27.283 23.062-6.664 5.633-10.048-8.428-30.537-25.61a4 4 0 00-5.153.01l-30.438 25.728-30.917 26.133a1 1 0 00.648 1.764l63.386-.122 20.064-.039-.196.166 59.808-.115 57.067-.11a1 1 0 00.64-1.766z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#ccc"
        d="M1056.136 767.933h-192a1 1 0 010-2h192a1 1 0 010 2z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M784.92 238.373a11.22 11.22 0 00-7.25-5.1 10.301 10.301 0 00-1.66-.21l-76.19-68.12a9.015 9.015 0 00-5.63-2.69 7.67 7.67 0 00-1.27-.04 9.055 9.055 0 00-5.96 15.48l29.17 25.43 47.9 41.75a11.916 11.916 0 00.35 2.33 11.266 11.266 0 009.67 8.37 11.092 11.092 0 006.05-1 11.407 11.407 0 003.88-2.97c.11-.13.21-.25.31-.39a11.293 11.293 0 00.63-12.84zM654.6 304.023l-.71-28.86-.6-23.95-.41-16.76-.81-32.67a9.046 9.046 0 00-15.71-5.25 6.923 6.923 0 00-.75.96 8.936 8.936 0 00-1.56 6.05l1.78 47.67.89 23.95 1.14 30.55a12.047 12.047 0 00-.89 1.41 11.248 11.248 0 00-.71 8.84 11.311 11.311 0 0015.56 6.63 11.3 11.3 0 002.78-18.57z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#ccc"
        d="M723.2 222.273l-7.07-19.15-11.44-31.02a16.006 16.006 0 00-9.95-9.68c-.18-.06-.37-.12-.55-.17a15.984 15.984 0 00-13.24 1.82l-38.53 24.44a16.093 16.093 0 00-5.27 21.75 276.323 276.323 0 0015.73 24.19c4.49 6.11 12.85 6.76 14.3 16.76h28.41l28.76-25.82z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <circle cx="101.165" cy="326.682" r="23.172" fill="#a0616a"></circle>
      <path
        fill="#a0616a"
        d="M338.916 417.193q.342.272.663.578a10.38 10.38 0 01-.417 15.483 9.722 9.722 0 01-.813.614l1.568 52.944a8.061 8.061 0 01-5.545 7.867l-.096.021-42.988 4.578a8.899 8.899 0 11-1.723-17.713l30.645-2.699 5.3-45.85a9.876 9.876 0 01-.972-.99 10.38 10.38 0 0114.378-14.833z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#a0616a"
        d="M139.328 649.174L150.895 649.174 156.397 604.561 139.326 604.561 139.328 649.174z"
      ></path>
      <path
        fill="#2f2e41"
        d="M317.242 751.858h22.779a14.516 14.516 0 0114.516 14.515v.472l-37.294.001z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#a0616a"
        d="M24.366 633.182L34.304 639.099 61.857 603.582 47.189 594.849 24.366 633.182z"
      ></path>
      <path
        fill="#2f2e41"
        d="M204.627 734.889l19.572 11.653a14.516 14.516 0 015.046 19.899l-.241.405-32.044-19.079zM334.488 739.877h-12.952a4.238 4.238 0 01-4.221-3.797l-13.057-122.737a1.415 1.415 0 00-2.697-.433l-23.556 52.159-41.703 68.002a4.266 4.266 0 01-5.33 1.666l-16.66-7.33a4.247 4.247 0 01-1.934-6.064l38.337-64.192 11.757-54.085c-3.698-23.455 12.155-58.326 12.316-58.676l.079-.17 43.667-17.069.22.269c17.22 56.513 27.714 117.332 19.966 208.567a4.274 4.274 0 01-4.232 3.89z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#3f3d56"
        d="M273.107 549.934l-.203-.427c-.097-.205-9.807-20.684-17.292-42.572a32.285 32.285 0 012.239-25.873 32.637 32.637 0 0120.812-16.005 32.677 32.677 0 0137.928 19.57c5.937 14.847 4.045 30.695 2.264 43.157l-.035.25-.227.109z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#2f2e41"
        d="M310.124 420.054H273.33v-16.039c8.076-3.208 15.978-5.937 20.755 0a16.038 16.038 0 0116.039 16.039z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#2f2e41"
        d="M271.293 401.185c-21.996 0-28.153 27.571-28.153 43.125 0 8.675 3.923 11.777 10.088 12.827l2.177-11.61 5.099 12.11c1.731.009 3.55-.025 5.436-.06l1.73-3.56 3.855 3.496c15.44.023 27.92 2.274 27.92-13.203 0-15.554-5.4-43.125-28.152-43.125z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#ccc"
        d="M373.864 767.933h-192a1 1 0 110-2h192a1 1 0 010 2z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#fff"
        d="M377.474 438.933h-43a9.51 9.51 0 01-9.5-9.5v-43a9.51 9.51 0 019.5-9.5h43a9.51 9.51 0 019.5 9.5v43a9.51 9.51 0 01-9.5 9.5z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#3f3d56"
        d="M377.474 438.933h-43a9.51 9.51 0 01-9.5-9.5v-43a9.51 9.51 0 019.5-9.5h43a9.51 9.51 0 019.5 9.5v43a9.51 9.51 0 01-9.5 9.5zm-43-60a7.508 7.508 0 00-7.5 7.5v43a7.508 7.508 0 007.5 7.5h43a7.508 7.508 0 007.5-7.5v-43a7.508 7.508 0 00-7.5-7.5z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#10b981"
        d="M369.974 421.433h-28a3.003 3.003 0 01-3-3v-21a3.003 3.003 0 013-3h7.883a2.981 2.981 0 012.555 1.427l2.198 3.573h15.364a3.003 3.003 0 013 3v16a3.003 3.003 0 01-3 3z"
        transform="translate(-180.864 -106.46)"
      ></path>
      <path
        fill="#a0616a"
        d="M369.064 432.042q.21.385.387.79a10.38 10.38 0 01-6.392 14.11 9.72 9.72 0 01-.988.249l-19.1 49.405a8.061 8.061 0 01-8.163 5.098l-.096-.018-41.397-12.462a8.899 8.899 0 115.286-16.993l29.29 9.404 22.677-40.202a9.876 9.876 0 01-.511-1.29 10.38 10.38 0 0119.007-8.09z"
        transform="translate(-180.864 -106.46)"
      ></path>
    </svg>
  );
}

export default Icon;
import React from 'react';
import Firebase from "../../static/svg/firebase"
import LogoReact from "../../static/svg/logoReact"

const Tech = () => (

  <section id="tec" className="container mx-auto py-16 md:py-24 lg:py-36 px-4 md:px-0">
    <div className="mt-16">
      <p
        className="text-green-600 font-bold tracking-wide text-sm "
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-duration="150"
      >
        TECNOLOGIAS
      </p>
      <h2
        className="text-3xl font-semibold "
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-duration="300"
      >
        Rápidas, eficientes y las ultimas tendencias. 
      </h2>
      <p 
        className="text-lg text-gray-900 max-w-2xl "
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-duration="600"
      >
        Creemos que tienes que estar realmente apasionado con las tecnologias que usas para sobresalir.
        Nosotros nos especializamos en un puñado de tecnologias de primera categoria, siguiendo las nuevas tendencias.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-8">
        
        {/* Card */}
        <div 
          className="flex-1 flex flex-col shadow-lg rounded-lg"
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="350"
        >
          <div className="flex-1 flex flex-col justify-between p-4 ">
            <div>
              <Firebase className="h-12 inline-block"/>
            </div>
            <div>
              <h5 className="font-bold mt-2 text-xl">Firebase</h5>
              <p className="text-gray-900">
              Firebase se trata de una plataforma móvil creada por Google, cuya principal función es desarrollar y facilitar la creación de apps de elevada calidad de una forma rápida, con el fin de que se pueda aumentar la base de usuarios.
              </p>
            </div>
          </div>
        </div>

        {/* Card */}
        <div 
          className="flex-1 flex flex-col shadow-lg rounded-lg"
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="350"
        >
          <div className="flex-1 flex flex-col justify-between p-4  ">
            <div>
              <LogoReact className="h-12 inline-block"/>
            </div>
            <div>
              <h5 className="font-bold mt-2 text-xl">React</h5>
              <p className="text-gray-900">
                React es un framework de JavaScript usado para construir Interfaces de UIs interactivas, hecho por Facebook.
                Ofrece un alta flexibilidad y escalabilidad, usado tanto en aplicaciones pequeñas como grandes proyectos.
              </p>
            </div>
          </div>
        </div>

        {/* Card */}
        <div 
          className="flex-1 flex flex-col shadow-lg rounded-lg"
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="350"
        >
          <div className="flex-1 flex flex-col justify-between p-4 ">
            <div>
              <svg
                className="h-12 inline-block"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid"
                viewBox="0 0 256 256"
              >
                <path
                  fill="#744C9E"
                  d="M128 0C57.3075981 0 0 57.307374 0 128c0 70.69285 57.3078221 128 128 128 70.69285 0 128-57.307374 128-128C256 57.307374 198.69285 0 128 0zM27.503973 129.334313l99.16149 99.161714c-54.4510118-.709722-98.4519919-44.710702-99.16149-99.161714zm122.992292 96.649011L30.0166761 105.503735C40.2328216 60.8232766 80.2223482 27.4871709 128 27.4871709c33.397489 0 62.984927 16.2929172 81.262736 41.3592366L195.346089 81.125855C180.519662 59.8637627 155.886614 45.9486835 128 45.9486835c-35.5051492 0-65.740477 22.5524961-77.1688404 54.1129525L155.938588 205.169064c25.525354-9.243413 45.156519-30.79047 51.731306-57.476739h-43.567261v-19.692101h45.948684v-.000448h18.461512V128c0 47.777652-33.335882 87.767178-78.016564 97.983324z"
                ></path>
              </svg>
            </div>
            <div>
              <h5 className="font-bold mt-2 text-xl">Gatsby</h5>
              <p className="text-gray-900">
                Gatsby es un framework de React usado para crear páginas super rapidas
                (Como la que estas viendo). Olvidate de WordPress y enseña a tus clientes
                 que tu página es tan rapida como tu negocio.
              </p>
            </div>
          </div>
        </div>      
      </div>
    </div>
  </section>
);
export default Tech;
